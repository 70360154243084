@use '../base/vars' as *;

.hero_img{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-image: url(../img/common/logo_hiroshima2.webp);
    background-repeat: no-repeat;
    background-position: right 60px top 40px;
    background-size: 94px auto;
    
    @include media_query(){
        height: 100svh;
        
        background-position: right 10px top 10px;
        background-size: 50px auto;
    }
    
    .hero_inner{
        width: 100%;
        max-width: 893px;
        padding: 20px;
    }
    
    img{
        width: 100%;
        height: auto;
    }
}



.extension{
    margin-top: 40px;
    @include rem(24);
    font-weight: bold;
    text-align: center;
    line-height: 1.5;
    color: #3C865A;
}





.introduction{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 130px 20px;
    
    @include media_query(){
        padding: 60px 20px;
    }
    
    .introduction_img{
        max-width: 1009px;
        height: auto;
    }
    
    .introduction_text{
        margin-top: 100px;
        max-width: 787px;
        height: auto;
    }
    
    .banner_dopter{
        width: 100%;
        height: auto;
        max-width: 800px;
        margin-top: 130px;
        
        @include media_query(){
            margin-top: 30px;
        }
    }
}






.section_title{
    font-family: 'Montserrat', sans-serif;
    @include rem(64);
    letter-spacing: .03em;
    font-weight: normal;
    color: #1e1e1e;
    
    @include media_query(){
        @include rem(40);
    }
}






.about{
    background-image: url(../img/about_bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 100px 0;
    
    @include media_query(){
        padding: 60px 20px;;
    }
    
    .about_inner{
        text-align: center;
        
        .section_title{
            color: #fff;
            margin-bottom: 80px;
            
            @include media_query(){
                margin-bottom: 30px;
            }
        }
        
        p{
            color: #fff;
            @include rem(18);
            line-height: 2;
            
            @include media_query(){
                @include rem(15);
            }
        }
    }
}





.dummy{
    width: 100%;
    height: 500px;
    display: grid;
    place-content: center;
    color: #f00;
    @include rem(18);
    background-color: #F0F0F0;
}

.scheme{
    background-color: #F2F2F2;
    padding: 120px 0;
    
    @include media_query(){
        padding: 60px 20px;
    }
    
    .section_title{
        text-align: center;
    }
    
    p{
        max-width: 717px;
        margin: 80px auto 0;
        @include rem(15);
        line-height: 1.7;
        
        @include media_query(){
            margin: 40px 0 0;
        }
    }
    
    .scheme_img{
        max-width: 990px;
        margin: 85px auto 0;
        text-align: center;
        
        @include media_query(){
            margin: 40px 0 0;
        }
        
        img{
            width: 100%;
            height: auto;
        }
    }
    
}






.challenge{
    .challenge_inner{
        max-width: 1020px;
        margin: 0 auto;
        padding: 200px 20px 100px;
        
        @include media_query(){
            padding: 60px 20px;
        }
        
        .section_title{
            text-align: center;
            margin-bottom: 80px;
            
            @include media_query(){
                margin-bottom: 30px;
            }
        }
        
        .challenge_links{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            
            @include media_query(){
                grid-template-columns: 1fr;
            }
            
            .challenge_link{
                a{
                    &:hover{
                        opacity: .7;
                    }
                    
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}






.contact{
    margin-top: 50px;
    
    .contact_inner{
        .section_title{
            text-align: center;
            margin-bottom: 80px;
            
            @include media_query(){
                margin-bottom: 30px;
            }
        }
    }
    
    .contact_area{
        max-width: 1020px;
        margin: 0 auto;
        padding: 50px 20px;
        background-color: #F0F0F0;
        
        h3{
            text-align: center;
            @include rem(21);
            line-height: 2;
            margin-bottom: 20px;
            
            @include media_query(){
                @include rem(18);
            }
        }
        
        p{
            text-align: center;
            @include rem(15);
            line-height: 1.7;
        }
        
        .btn_contact{
            margin-top: 50px;
            
            @include media_query(){
                margin-top: 30px;
            }
            
            a{
                @include rem(18);
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                width: 100%;
                max-width: 400px;
                height: 77px;
                margin: 0 auto;
                border: solid 1px #1E1E1E;
                background-color: #fff;
                @include rem(18);
                font-weight: bold;
                text-decoration: none;
                color: #1E1E1E;
                
                @include media_query(){
                    @include rem(16);
                }
                
                &:hover{
                    opacity: .7;
                }
                
                img{
                    max-width: 34px;
                    height: auto;
                    
                    @include media_query(){
                        max-width: 30px;
                    }
                }
            }
        }
    }
}





.wavestate{
    max-width: 1020px;
    margin: 0 auto 80px;
    
    ul{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        
        @include media_query(){
            grid-template-columns: 1fr;
            gap: 10px;
        }
        
        li{
            list-style: none;
            
            a{
                &[href]{
                    
                    &:hover{
                        opacity: .7;
                    }
                }
                
                img{
                    width: 100%;
                    height: auto;
                    aspect-ratio: 1 / 1;
                }
            }
        }
    }
}
