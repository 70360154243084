@use '../base/vars' as *;

.wrap{
    display: grid;
    grid-template-columns: 1fr 60px;
    overflow-x: hidden; 
    
    @include media_query(){
        display: block;
    }
}







.header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: calc(100% - 60px);
    
    @include media_query(){
        width: 100%;
    }
    
    .header_inner{
        width: 100%;
        height: 90px;
        display: flex;
        align-items: center;
        padding: 0 60px;
        
        @include media_query(){
            height: 60px;
            padding: 0 20px;
        }
        
        .logo{
            img{
                width: 100%;
                max-width: 325px;
                height: auto;
                
                @include media_query(){
                    max-width: 280px;
                }
            }
        }
    }
}









.footer{
    margin-top: 120px;
    border-top: solid 1px #1e1e1e;
    
    @include media_query(){
        margin-top: 60px;
        padding-bottom: 80px;
    }
    
    .footer_inner{
        padding: 70px 60px 50px;
        
        @include media_query(){
            padding: 30px 20px;
        }
        
        .footer_logo{
            text-align: center;
            
            img{
                max-width: 318px;
                height: auto;
            }
        }
        
        .footer_foot{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 60px;
            
            @include media_query(){
                grid-template-columns: 1fr;
                text-align: center;
            }
            
            .footer_foot_item{
                .logo_hiroshima{
                    max-width: 86px;
                    height: auto;
                    margin-bottom: 20px;
                }
                
                .logo_hiroshima2{
                    max-width: 94px;
                    height: auto;
                    margin-bottom: 35px;
                }
                
                p{
                    @include rem(12);
                    line-height: 1.7;
                    
                    small{
                        @include rem(11);
                        line-height: 2;
                    }
                }
                
                & + .footer_foot_item{
                    text-align: right;
                    
                    @include media_query(){
                        text-align: center;
                        margin-top: 30px;
                    }
                }
                
                a{
                    color: #333;
                    
                    &:hover{
                        opacity: .7;
                    }
                }
            }
        }
        
    }
}
