@use '../base/vars' as *;

.brains{
    max-width: 1280px;
    margin: 0 auto;
    
    .brains_head{
        img{
            width: 100%;
            height: auto;
        }
    }
}

.brains_list{
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px 80px;
    
    @include media_query(){
        margin-top: 50px;
        grid-template-columns: 1fr;
        padding-inline: 20px;
        gap: 50px;
    }
    
    .brains_item{
        .brains_item_head{
            display: grid;
            grid-template-columns: 115px 1fr;
            gap: 30px;
            
            @include media_query(){
                gap: 20px;
            }
            
            .brains_item_img{
                width: 115px;
                height: 115px;
                border-radius: 50%;
                overflow: hidden;
                
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            
            .brains_item_info{
                padding-top: 10px;
                line-height: 1.5;
                
                h2{
                    @include rem(24);
                }
                
                .alphabet{
                    margin-top: 8px;
                    @include rem(12);
                }
                
                .affiliation{
                    margin-top: 16px;
                    @include rem(14);
                }
            }
        }
        
        .brains_item_body{
            margin-top: 30px;
            
            @include media_query(){
                margin-top: 16px;
            }
            
            dt{
                @include rem(13);
                border-left: solid 4px #F55A32;
                padding-left: 5px;
                color: #8E8E8E;
                font-weight: bold;
            }
            
            dd{
                margin-top: 10px;
            }
            
            .area{
                @include rem(14);
                line-height: 1.5;
            }
            
            .body{
                margin-top: 16px;
                
                dd{
                    margin-top: 16px;
                    border-top: solid 1px #1E1E1E;
                    border-bottom: solid 1px #1E1E1E;
                    padding: 15px 0;
                    @include rem(14);
                    line-height: 1.5;
                }
            }
        }
    }
}

.sortorder{
    @include rem(12);
    margin-top: 50px;
    // text-align: right;
}
