@use '../base/vars' as *;





.sidebar {
    .sidebar_inner{
        position: fixed;
        right: 0;
        top: 0;
        z-index: 9;
        display: flex;
        flex-direction: column;
        width: 60px;
        height: 100vh;
        border-left: solid 1px #333;
        background-color: #fff;
        
        @include media_query(){
            bottom: 0;
            top: auto;
            flex-direction: row;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            height: 60px;
            border-left: none;
            border-top: solid 1px #333;
        }
    }
    
    
    .sidebar_hero{
        &.current{
            a{
                background-color: #D8E7DE;
            }
        }
        
        a{
            display: block;
            text-decoration: none;
            text-align: center;
            padding: 2vh 0;
            color: #3C865A;
            transition: background-color .25s;
            
            &:hover{
                background-color: #D8E7DE;
            }
            
            @include media_query(){
                height: 100%;
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            
            img{
                width: 34px;
                height: auto;
                
                @include media_query(){
                    width: auto;
                    height: 32px;
                }
            }
        }
        
        span{
            width: 100%;
            display: flex;
            align-items: center;
            writing-mode: vertical-rl;
            @include rem(17);
            font-weight: bold;
            
            @include media_query(){
                display: inline;
                writing-mode: horizontal-tb;
                @include rem(12);
            }
        }
    }
    
    .sidebar_brain{
        border-top: solid 1px #333;
        
        @include media_query(){
            border-top: none;
            border-left: solid 1px #333;
        }
        
        &.current{
            a{
                background-color: #FDDED6;
            }
        }
        
        a{
            display: block;
            text-decoration: none;
            text-align: center;
            padding: 2vh 0;
            color: #F55A32;
            transition: background-color .25s;
            
            @include media_query(){
                padding: 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            
            &:hover{
                background-color: #FDDED6;
            }
            
            img{
                width: 34px;
                height: auto;
                
                @include media_query(){
                    width: auto;
                    height: 32px;
                }
            }
            
            
        }
        
        span{
            width: 100%;
            display: flex;
            align-items: center;
            writing-mode: vertical-rl;
            @include rem(17);
            font-weight: bold;
            
            @include media_query(){
                display: inline;
                writing-mode: horizontal-tb;
                @include rem(12);
            }
        }
    }
    
    .sidebar_contact{
        border-top: solid 1px #333;
        
        @include media_query(){
            border-top: none;
            border-left: solid 1px #333;
        }
        
        a{
            display: block;
            text-decoration: none;
            text-align: center;
            padding: 2vh 0;
            color: #333;
            transition: opacity .25s;
            
            @include media_query(){
                height: 100%;
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            
            &:hover{
                opacity: .7;
            }
        }
        
        span{
            width: 100%;
            display: flex;
            align-items: center;
            writing-mode: vertical-rl;
            @include rem(17);
            font-weight: bold;
            
            @include media_query(){
                width: auto;
                text-align: center;
                writing-mode: horizontal-tb;
                @include rem(12);
            }
        }
    }
    
    
    
    
    .sidebar_other{
        margin-top: 3vh;
        
        @include media_query(){
            display: none;
        }
        
        .sidebar_hiroshima{
            a{
                display: block;
                text-decoration: none;
                text-align: center;
                padding: 2vh 0;
                transition: opacity .25s;
                
                &:hover{
                    opacity: .7;
                }
                
                img{
                    width: 14px;
                    height: auto;
                }
            }
        }
        
        .sidebar_scroll{
            a{
                display: block;
                text-decoration: none;
                text-align: center;
                padding: 2vh 0;
                transition: opacity .25s;
                
                &:hover{
                    opacity: .7;
                }
                
                img{
                    width: 8px;
                    height: auto;
                }
            }
        }
    }
    
    .sidebar_scrolltop{
        margin-top: auto;
        
        @include media_query(){
            display: none;
        }
        
        a{
            display: block;
            text-decoration: none;
            text-align: center;
            padding: 2vh 0;
            transition: opacity .25s;
            
            &:hover{
                opacity: .7;
            }
            
            img{
                width: 9px;
                height: auto;
            }
        }
    }
}
