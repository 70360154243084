@use '../base/vars' as *;
.header{
    .header_inner{
        
        .logo{
            @include media_query(){
                display: flex;
                align-items: center;
                width: 100%;
            }
            
            .drawer{
                display: none;
                margin-left: auto;
                max-width: 25px;
                
                @include media_query(){
                    display: block;
                }
            }
        }
        
        .nav{
            display: flex;
            align-items: center;
            margin-left: auto;
            gap: 40px;
            
            @include media_query(){
                visibility: hidden;
                opacity: 0;
            }
            
            transition: visibility .25s, opacity .25s;
            
            &.open{
                visibility: visible;
                opacity: 1;
            }
            
            @include media_query(){
                flex-direction: column;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 999;
                width: 100vw;
                height: 100vh;
                padding-top: 30%;
                background-color: var(--main-color);
                
                .btn_apply{
                    a{
                        width: 250px;
                        height: 65px;
                        @include rem(18);
                        background-color: #fff;
                        color: var(--main-color);
                        font-weight: bold;
                    }
                }
            }
            
            .close{
                display: none;
                
                @include media_query(){
                    display: block;
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    width: 100%;
                    max-width: 25px;
                    height: auto;
                }
            }
            
            .nav_ul{
                display: flex;
                gap: 40px;
                
                @include media_query(){
                    flex-direction: column;
                    align-items: center;
                }
                
                li{
                    a,span{
                        text-decoration: none;
                        color: #1E1E1E;
                        cursor: pointer;
                        
                        @include media_query(){
                            @include rem(20);
                            font-weight: bold;
                            color: #fff;
                            text-align: center;
                        }
                        
                        &:hover{
                            opacity: .7;
                        }
                    }
                    
                    &.has_child{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        
                        &::after{
                            content: "";
                            width: 13px;
                            height: 13px;
                            margin-left: 5px;
                            background-image: url(../img/adopter/icon_arrow_bottom.webp);
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                            transform: rotate(180deg);
                            
                            transition: transform .25s;
                        }
                        
                        &:hover{
                            &::after{
                                transform: rotate(0);
                            }
                            
                            ul{
                                opacity: 1;
                                visibility: visible;
                                top: 100%;
                            }
                        }
                        
                        ul{
                            opacity: 0;
                            visibility: hidden;
                            position: absolute;
                            top: 0%;
                            // left: 0;
                            z-index: 9;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            background-color: #fff;
                            box-shadow: 1px 1px 2px rgba(#000, .4);
                            padding: 20px;
                            border-radius: 5px;
                            
                            transition: top .25s, visibility .25s, opacity .25s;
                            
                            li{
                                & + li{
                                    margin-top: 15px;
                                }
                                
                                a[href]{
                                    opacity: 1;
                                    cursor: pointer;
                                    
                                    &:hover{
                                        opacity: .7;
                                    }
                                }
                                
                                a{
                                    opacity: .3;
                                    white-space: nowrap;
                                    @include rem(16);
                                    cursor: auto;
                                }
                            }
                        }
                    }
                    
                }
            }
        }
    }
}

body.hero{
    padding-top: 120px;
    --main-color: #3C865A;
    --sub-color: #F5F9F7;
    
    @include media_query(){
        padding-top: 60px
    }
    
    .hero_about{
        background-image: url(../img/hero/icon_about.webp);
        background-repeat: no-repeat;
        background-size: 175px auto;
        background-position: left 70% top 30px;
        
        @include media_query(){
            background-size: 100px auto;
            background-position: right 10px top 10px;
        }
    }
}

body.brain{
    padding-top: 120px;
    --main-color: #F55A32;
    --sub-color: #FEF7F5;
    
    @include media_query(){
        padding-top: 60px
    }
    
    .hero_about{
        background-image: url(../img/hero/icon_about_brain.webp);
        background-repeat: no-repeat;
        background-size: 224px auto;
        background-position: left 70% top 10px;
        
        @include media_query(){
            background-size: 100px auto;
            background-position: right 10px top 10px;
        }
    }
}

.btn_apply{
    a{
        display: grid;
        place-content: center;
        width: 172px;
        height: 45px;
        border-radius: 30px;
        text-decoration: none;
        color: #fff;
        background-color: var(--main-color);
        @include rem(15);
        
        &:hover{
            opacity: .7;
        }
    }
    
    &.disabled{
        a{
            cursor: auto;
            opacity: .4;
        }
    }
}

.btn_apply_l{
    display: flex;
    justify-content: center;
    
    a{
        display: grid;
        place-content: center;
        width: 400px;
        height: 100px;
        border-radius: 50px;
        text-decoration: none;
        color: #fff;
        background-color: var(--main-color);
        @include rem(24);
        line-height: 1.4;
        
        @include media_query(){
            width: 250px;
            height: 60px;
            @include rem(18);
        }
        
        &:hover{
            opacity: .7;
        }
    }
    
    &.briefing{
        a{
            background-color: #F55A32;
        }
    }
    
    &.disabled{
        a{
            cursor: auto;
            opacity: .4;
        }
    }
}




.hero_hero_img{
    
    .hero_hero_img_banner{
        text-align: center;
        margin-bottom: 80px;
        
        a[href]{
            transition: opacity .25s;
            
            &:hover{
                opacity: .7;
            }
        }
        
        img{
            width: 100%;
            height: auto;
            max-width: 800px;
        }
    }
    
    .hero_hero_img_inner{
        max-width: 900px;
        padding: 70px 20px;
        margin: 0 auto;
        background-image: url(../img/plate_bg.webp);
        background-repeat: no-repeat;
        background-position: top left 20px;
        background-size: contain;
        text-align: center;
        
        @include media_query(){
            padding: 30px 40px;
        }
        
        img{
            width: 100%;
            max-width: 640px;
            height: auto;
        }
        
        p{
            text-align: left;
            max-width: 640px;
            margin: 35px auto 0;
            @include rem(21);
            line-height: 2;
            font-weight: bold;
            
            @include media_query(){
                @include rem(18);
                margin: 20px 0 0;
            }
        }
    }
}

.hero_hero_img_bottom{
    text-align: center;
    
    p{
        @include rem(18);
        line-height: 1.8;
        font-weight: bold;
    }
    
    h3{
        margin-top: 20px;
        @include rem(60);
        
        @include media_query(){
            @include rem(30);
        }
    }
    
    .btn_apply_l{
        margin-top: 40px;
    }
    
    .extension{
        margin-top: 40px;
        @include rem(24);
        font-weight: bold;
        text-align: center;
        line-height: 1.5;
        // color: var(--main-color);
        color: #f00;
    }
    
    & + .hero_hero_img_banner{
        margin-top: 40px;
    }
}


.section_title{
    @include rem(64);
    color: var(--main-color);
    letter-spacing: .02em;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
}


.hero_about{
    margin-top: 120px;
    padding: 120px 0;
    background-color: var(--sub-color);
    
    @include media_query(){
        margin-top: 60px;
        padding: 60px 20px;
    }
    
    .hero_about_inner{
        .hero_about_head{
            text-align: center;
            
            .section_title{
                margin-bottom: 85px;
                
                @include media_query(){
                    margin-bottom: 40px;
                }
            }
            
            h3{
                @include rem(24);
                line-height: 1.7;
                margin-bottom: 60px;
                
                @include media_query(){
                    @include rem(20);
                    margin-bottom: 30px;
                }
                
                span{
                    border-bottom: solid 1px #333;
                }
            }
            
            p{
                @include rem(17);
                line-height: 2;
                
                @include media_query(){
                    @include rem(15);
                    text-align: left;
                    
                    br{
                        display: none;
                    }
                }
            }
            
            .hero_about_head_img{
                margin-top: 60px;
                
                @include media_query(){
                    margin-top: 30px;
                }
                
                img{
                    width: 100%;
                    max-width: 750px;
                    height: auto;
                }
            }
        }
        
        .hero_about_main{
            max-width: 1020px;
            margin: 120px auto 0;
            
            @include media_query(){
                margin: 60px 0 0;
            }
            
            .hero_about_main_inner{
                & + .hero_about_main_inner{
                    margin-top: 85px;
                }
            }
            
            h3{
                @include rem(24);
                line-height: 1.7;
                text-align: center;
                
                @include media_query(){
                    @include rem(20);
                }
            }
            
            .hero_about_main_title{
                margin-top: 70px;
                background-color: var(--main-color);
                color: #fff;
                padding: 10px 20px;
                @include rem(32);
                text-align: center;
                
                @include media_query(){
                    margin-top: 30px;
                    @include rem(20);
                }
                
                & + p{
                    @include rem(15);
                    line-height: 2;
                    max-width: 720px;
                    margin: 40px auto 0;
                }
            }
            
            .hero_about_main_img{
                margin-top: 55px;
                
                @include media_query(){
                    margin-top: 25px;
                }
                
                img{
                    width: 100%;
                    height: auto;
                }
            }
            
            .hero_about_main_wrap{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 40px;
                margin-top: 60px;
                
                @include media_query(){
                    grid-template-columns: repeat(2, 1fr);
                    gap: 20px;
                }
                
                .hero_about_main_item{
                    img{
                        width: 100%;
                        height: auto;
                    }
                    
                    h5{
                        @include rem(17);
                        text-align: center;
                        margin-top: 20px;
                        line-height: 1.3;
                        
                        @include media_query(){
                            margin-top: 10px;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}





.hero_benefit{
    padding: 120px 0;
    
    @include media_query(){
        padding: 60px 20px;
    }
    
    .hero_benefit_inner{
        .section_title{
            text-align: center;
        }
        
        .hero_benefit_head{
            margin-top: 40px;
            text-align: center;
            
            @include media_query(){
                margin-top: 20px;
            }
            
            h3{
                @include rem(30);
                margin-top: 45px;
                
                @include media_query(){
                    @include rem(20);
                    margin-top: 20px;
                }
            }
            
            p{
                @include rem(15);
                line-height: 2;
                margin-top: 30px;
                
                @include media_query(){
                    @include rem(14);
                    margin-top: 15px;
                    br{
                        display: none;
                    }
                }
            }
        }
        
        
    }
    
    .brain_benefit{
        max-width: 877px;
        margin: 60px auto 0;
        padding: 55px 60px;
        background-color: var(--sub-color);
        
        @include media_query(){
            margin-top: 30px;
            padding: 20px;
        }
        
        ul{
            li{
                list-style: none;
                line-height: 1.5;
                padding-left: 1em;
                text-indent: -1em;
                
                span{
                    color: var(--main-color);
                }
                
                & + li{
                    margin-top: 10px;
                }
            }
        }
        
        p{
            margin-top: 15px;
            @include rem(15);
        }
    }
    
    
    
    
    .hero_benefit_main{
        margin-top: 65px;
        
        @include media_query(){
            margin-top: 30px;
        }
        
        .hero_benefit_main_inner{
            max-width: 917px;
            margin: 0 auto;
            padding: 0 20px;
            
            @include media_query(){
                padding: 0;
            }
            
            & + .hero_benefit_main_inner{
                margin-top: 60px;
            }
            
            h4{
                @include rem(21);
                color: #fff;
                background-color: var(--main-color);
                padding: 10px 20px;
                text-align: center;
            }
            
            .hero_benefit_main_wrap{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 45px 40px;
                margin-top: 45px;
                
                @include media_query(){
                    grid-template-columns: 1fr;
                    gap: 30px;
                    margin-top: 25px;
                }
            }
            
            .hero_benefit_main_item{
                background-color: var(--sub-color);
                padding: 40px;
                
                @include media_query(){
                    padding: 20px;
                }
                
                h5{
                    color: var(--main-color);
                    text-align: center;
                    @include rem(24);
                    line-height: 1.7;
                    margin-bottom: 35px;
                    
                    @include media_query(){
                        @include rem(20);
                        margin-bottom: 20px;
                    }
                }
                
                p{
                    @include rem(15);
                    line-height: 1.7;
                    
                    @include media_query(){
                        @include rem(14);
                    }
                }
            }
        }
    }
    
    .brain_img{
        margin-top: 80px;
        text-align: center;
        
        img{
            width: 100%;
            max-width: 1020px;
            margin: 0 auto;
        }
    }
}

.btn_matching{
    display: flex;
    justify-content: center;
    
    a{
        display: grid;
        place-content: center;
        width: 250px;
        height: 65px;
        border-radius: 30px;
        text-decoration: none;
        color: #fff;
        background-color: #ccc;
        @include rem(15);
        
        &[href]{
            background-color: var(--main-color);
            
            &:hover{
            opacity: .7;
        }
        }
        
        
    }
}

.hero_judge{
    .hero_judge_inner{
        max-width: 1020px;
        margin: 120px auto 0;
        
        @include media_query(){
            padding: 0 20px;
            margin: 60px 0 0;
        }
        
        .section_title{
            text-align: center;
            margin-bottom: 30px;
            
            @include media_query(){
                margin-bottom: 15px;
            }
        }
        
        .hero_judge_main{
            h3{
                text-align: center;
                margin-bottom: 65px;
                @include rem(30);
                
                @include media_query(){
                    margin-bottom: 30px;
                    @include rem(20);
                }
            }
            
            .hero_judge_item{
                display: grid;
                grid-template-columns: 250px 1fr;
                background-color: var(--main-color);
                
                @include media_query(){
                    grid-template-columns: 1fr;
                }
                
                & + .hero_judge_item{
                    margin-top: 40px;
                    
                    @include media_query(){
                        margin-top: 20px;
                    }
                }
                
                .hero_judge_item_img{
                    img{
                        width: 100%;
                        height: 100%;
                        aspect-ratio: 250 / 214;
                        object-fit: cover;
                        vertical-align: bottom;
                    }
                }
                
                .hero_judge_item_main{
                    padding-top: 25px;
                    display: grid;
                    grid-template-columns: 35.71428% 1fr;
                    
                    @include media_query(){
                        grid-template-columns: 1fr;
                        padding: 0;
                    }
                }
                
                .hero_judge_item_main_title{
                    padding: 0 30px;
                    color: #fff;
                    
                    @include media_query(){
                        padding: 15px 10px;
                    }
                    
                    h4{
                        @include rem(24);
                        letter-spacing: .2em;
                        
                        @include media_query(){
                            @include rem(18);
                        }
                        
                        span{
                            display: block;
                            @include rem(16);
                            color: #F8F399;
                            letter-spacing: .03em;
                            margin-bottom: 20px;
                            
                            @include media_query(){
                                @include rem(15);
                                margin-bottom: 10px;
                            }
                        }
                    }
                    
                    p{
                        margin-top: 15px;
                        line-height: 1.7;
                        
                        @include media_query(){
                            margin-top: 10px;
                        }
                        
                        small{
                            @include rem(13);
                            
                            @include media_query(){
                                @include rem(12);
                            }
                        }
                    }
                }
                
                .hero_judge_item_main_text{
                    border-left: solid 1px #fff;
                    padding: 0 20px 25px;
                    
                    @include media_query(){
                        border-left: none;
                        padding: 0 10px 15px;
                    }
                    
                    p{
                        @include rem(13);
                        line-height: 1.6;
                        color: #fff;
                    }
                }
            }
        }
    }
}






.hero_apply{
    margin-top: 120px;
    padding: 120px 0;
    background-color: var(--sub-color);
    
    @include media_query(){
        margin-top: 60px;
        padding: 60px 20px;
    }
    
    .hero_apply_inner{
        max-width: 1220px;
        margin: 0 auto;
        
        .section_title{
            text-align: center;
            margin-bottom: 30px;
        }
    }
    
    .hero_apply_head{
        h3{
            text-align: center;
            margin-bottom: 65px;
            @include rem(30);
        }
    }
    
    
    .guideline{
        // display: flex;
        // flex-wrap: wrap;
        // margin-top: 95px;
        
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "apply01 apply02"
            "apply03 apply04"
            "apply05 apply06"
            "apply05 apply07";
        
        @include media_query(){
            margin-top: 50px;
            grid-template-columns: 1fr;
            grid-template-areas:
                "apply01"
                "apply02"
                "apply03"
                "apply04"
                "apply05"
                "apply06"
                "apply07";
        }
        
        &.brain_guideline{
            grid-template-areas:
            "apply01 apply03"
            "apply02 apply03"
            "apply04 apply05"
            "apply04 apply06"
            "apply07 apply07";
            
            @include media_query(){
                grid-template-areas:
                "apply01"
                "apply02"
                "apply03"
                "apply04"
                "apply05"
                "apply06"
                "apply07";
            }
            
            .guideline_item{
                &:nth-child(2){
                    border-top: none;
                    border-right: none;
                    
                    @include media_query(){
                        border-bottom: none;
                        border-right: solid 3px #333;
                    }
                }
                &:nth-child(3){
                    border-top: solid 3px #333;
                    border-right: solid 3px #333;
                }
                &:nth-child(4){
                    grid-area: apply04;
                    border-top: none;
                    border-right: none;
                    
                    @include media_query(){
                        border-right: solid 3px #333;
                    }
                }
                &:nth-child(5){
                    border-right: solid 3px #333;
                }
                
                .apply03{
                    ul{
                        padding-left: 0;
                    }
                }
            }
        }
    }
    
    .guideline_item{
        border: solid 3px #333;
        padding: 30px 40px;
        
        @include media_query(){
            padding: 20px;
        }
        
        a{
            color: #333;
            
            &:hover{
                opacity: .7;
            }
        }
        
        &:nth-child(1){
            grid-area: apply01;
            border-right: none;
            
            @include media_query(){
                border-right: solid 3px #333;
            }
        }
        &:nth-child(2){
            grid-area: apply02;
        }
        &:nth-child(3){
            grid-area: apply03;
            border-top: none;
            border-right: none;
            
            @include media_query(){
                border-right: solid 3px #333;
            }
        }
        &:nth-child(4){
            grid-area: apply04;
            border-top: none;
        }
        &:nth-child(5){
            grid-area: apply05;
            border-top: none;
            border-right: none;
            
            @include media_query(){
                border-right: solid 3px #333;
            }
        }
        &:nth-child(6){
            grid-area: apply06;
            border-top: none;
        }
        &:nth-child(7){
            grid-area: apply07;
            border-top: none;
        }
        
        &:not(:first-child){
            @include media_query(){
                border-top: none;
            }
        }
        
        // &:nth-child(1),
        // &:nth-child(2),
        // &:nth-child(3),
        // &:nth-child(4){
        //     border-bottom: none;
        // }
        
        // &:nth-child(even){
        //     border-left: none;
            
        //     @include media_query(){
        //         border-left: solid 2px #333;
        //     }
        // }
        
        // &:nth-child(5){
        //     width: 100%;
        //     // border-top: none;
            
        //     @include media_query(){
        //         border-top: solid 2px #333;
        //     }
            
        //     h4{
        //         @include media_query(){
        //             align-items: flex-start;
        //         }
        //     }
        // }
        
        // @include media_query(){
        //     &:not(:last-child){
        //         border-bottom: none !important;
        //     }
        // }
        
        dl{
            dd{
                @include rem(16);
                padding-left: 20px;
                margin-top: -20px;
                // padding-top: 30px;
                
                // @include media_query(){
                //     padding-top: 15px;
                // }
                
                @include media_query(){
                    margin-top: -20px;
                }
                
                @include media_query(){
                    padding-left: 0;
                }
                
                ul{
                    line-height: 1.8;
                    list-style: none;
                }
                
                p{
                    line-height: 1.8;
                }
            }
        }
        
        h4{
            display: flex;
            align-items: center;
            margin: -45px 0 0 -25px;
            
            @include media_query(){
                margin: -25px 0 0 -30px;
            }
            
            img{
                width: 125px;
                height: 125px;
                object-fit: contain;
                
                @include media_query(){
                    width: 125px;
                    height: 125px;
                }
            }
            
            span{
                @include rem(21);
                margin-left: -20px;
                
                @include media_query(){
                    @include rem(18);
                    line-height: 1.6;
                    letter-spacing: -.05em;
                }
                
                small{
                    @include rem(16);
                    font-weight: normal;
                    margin-left: 30px;
                    
                    @include media_query(){
                        margin-left: 0;
                    }
                }
            }
        }
        
        .apply01_title{
            @include rem(35);
            font-weight: bold;
            text-align: center;
            margin-top: -30px;
            line-height: 1;
            
            @include media_query(){
                @include rem(25);
            }
            
            span{
                @include rem(94);
                
                @include media_query(){
                    @include rem(50);
                }
            }
        }
        
        .apply01_text{
            @include rem(24);
            font-weight: bold;
            text-align: center;
            line-height: 1.5;
            
            small{
                @include rem(14);
            }
        }
        
        .apply02_ul{
            list-style: none;
            
            li{
                padding-left: 1em;
                text-indent: -1em;
            }
        }
        
        .apply02_ul_fill{
            li{
                padding-left: 0;
                text-indent: 0;
            }
        }
        
        .apply03{
            h5{
                @include rem(18);
                margin-bottom: 15px;
                
                &:not(:first-child){
                    margin-top: 30px;
                }
            }
            
            p{
                padding-left: 1em;
                text-indent: -1em;
            }
            
            ul{
                padding-left: 1em;
                
                li{
                    padding-left: 1em;
                    text-indent: -1em;
                }
            }
        }
        
        .apply04{
            ul{
                li{
                    padding-left: 1em;
                    text-indent: -1em;
                }
            }
            
            p{
                margin-top: 1em;
                
                small{
                    @include rem(13);
                }
            }
        }
        
        .apply05{
            p{
                padding-left: 1em;
                text-indent: -1em;
                
                small{
                    padding-left: 0;
                    text-indent: 0;
                    @include rem(12);
                }
            }
            
            ul{
                padding-left: 1em;
                
                li{
                    padding-left: 1em;
                    text-indent: -1em;
                }
            }
        }
        
        .guideline_item_flex{
            display: flex;
            align-items: center;
            
            @include media_query(){
                display: block;
            }
            
            dt{
                padding-bottom: 0;
            }
            
            dd{
                flex: 1;
                margin-top: -40px;
                padding-left: 50px;
                padding-bottom: 0;
                
                @include media_query(){
                    margin: -20px 0 0 0;
                    padding: 0;
                }
            }
        }
        
        &.apply06_item{
            padding-bottom: 0;
            
            @include media_query(){
                padding-bottom: 20px;
            }
        }
        
        // .nop{
        //     //text-align: center;
        //     letter-spacing: .05em;
        //     @include rem(21);
        //     line-height: 1.2;
            
        //     @include media_query(){
        //         @include rem(15);
        //     }
            
        //     span{
        //         @include rem(50);
                
        //         @include media_query(){
        //             @include rem(37);
        //         }
        //     }
            
        //     small{
        //         display: block;
        //         margin-top: 10px;
        //     }
            
        //     .font_l{
        //         margin-top: 20px;
        //     }
        // }
        
        // .font_l{
        //     @include rem(24);
            
        //     @include media_query(){
        //         @include rem(18);
        //     }
        // }
        
        // .font_n{
        //     @include rem(16);
        //     line-height: 1.8;
        //     letter-spacing: .03em;
        //     text-align: justify;
            
        //     @include media_query(){
        //         @include rem(15);
        //     }
        // }
        
        // .font_s{
        //     @include rem(14);
            
        //     @include media_query(){
        //         @include rem(11);
        //         letter-spacing: -0.01em;
        //     }
        // }
        
        // .font_right{
        //     text-align: right;
        //     margin-top: 1em;
        // }
        
        // ul{
        //     li{
        //         list-style: none;
        //         @include rem(16);
        //         line-height: 1.8;
                
        //         @include media_query(){
        //             @include rem(15);
        //         }
        //     }
        // }
        
        // .apply03_p{
        //     @include rem(16);
        //     line-height: 1.8;
        //     padding-left: 1em;
        //     text-indent: -1em;
        // }
        
        // .apply03_ul{
        //     margin-top: 1em;
            
        //     li{
        //         text-indent: -3em;
        //         padding-left: 3em;
        //     }
        // }
        
        // .apply04_ul{
        //     li{
        //         text-indent: -1em;
        //         padding-left: 1em;
        //     }
            
        //     & + p{
        //         margin-top: 1em;
        //     }
        // }
        
        // .apply05_item_wrap{
        //     display: flex;
        //     flex-wrap: wrap;
        //     margin-left: -20px;
            
        //     @include media_query(){
        //         display: block;
        //         margin: 0;
        //     }
            
        //     .apply05_item{
        //         width: calc(50% - 20px);
        //         margin-left: 20px;
                
        //         @include media_query(){
        //             width: 100%;
        //             margin: 0;
        //         }
                
        //         ul{
        //             li{
        //                 padding-left: 1em;
        //                 text-indent: -1em;
        //             }
        //         }
        //     }
        // }
    }
    
    .deadline{
        width: 100%;
        
        dl{
            width: 100%;
            
            dd{
                width: 100%;
                height: 125px;
                background-color: #333;
                border: solid 3px #333;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                
                @include media_query(){
                    flex-direction: column;
                }
                
                h4{
                    margin-right: 25px;
                    
                    img{
                        width: 100%;
                        max-width: 130px;
                        height: auto;
                        
                        @include media_query(){
                            max-width: 100px;
                        }
                    }
                }
                
                p{
                    margin-top: -20px;
                    @include rem(21);
                    
                    @include media_query(){
                        margin: 10px 0 0;
                        @include rem(18);
                    }
                    
                    span{
                        @include rem(50);
                        
                        @include media_query(){
                            @include rem(25);
                        }
                    }
                }
            }
        }
    }
    
    .btn_apply_l{
        margin-top: 80px;
        
        @include media_query(){
            margin-top: 40px;
        }
    }
    
    
}

.apply_schedule{
    margin-top: 120px;
    padding: 0 20px;
    text-align: center;
    
    @include media_query(){
        margin-top: 60px;
    }
    
    .section_title{
        margin-bottom: 40px;
    }
    
    h4{
        @include rem(30);
        
        @include media_query(){
            @include rem(20);
        }
    }
    
    p{
        @include rem(15);
        line-height: 1.8;
        margin-top: 40px;
        
        @include media_query(){
            br{
                display: none;
            }
        }
    }
    
    .schedule_img{
        margin-top: 60px;
        
        @include media_query(){
            margin-top: 30px;
            overflow: scroll;
        }
        
        img{
            width: 100%;
            max-width: 1020px;
            height: auto;
            
            @include media_query(){
                min-width: 800px;
            }
        }
        
        &.schedule_wave01{
            img{
                max-width: 840px;
            }
        }
    }
}





.hero_event{
    padding: 120px 0;
    
    @include media_query(){
        padding: 60px 20px;
    }
    
    .hero_event_inner{
        .section_title{
            text-align: center;
        }
        
        .hero_event_head{
            margin-top: 40px;
            text-align: center;
            
            @include media_query(){
                margin-top: 20px;
            }
            
            h3{
                @include rem(30);
                margin-top: 45px;
                
                @include media_query(){
                    @include rem(20);
                    margin-top: 20px;
                }
            }
            
            p{
                @include rem(15);
                line-height: 2;
                margin-top: 30px;
                
                @include media_query(){
                    @include rem(14);
                    margin-top: 15px;
                    br{
                        display: none;
                    }
                }
            }
        }
        
        
    }
    
    .hero_event_main{
        margin-top: 65px;
        
        @include media_query(){
            margin-top: 30px;
        }
        
        .hero_event_main_inner{
            max-width: 917px;
            margin: 0 auto;
            padding: 0 20px;
            
            @include media_query(){
                padding: 0;
            }
            
            & + .hero_event_main_inner{
                margin-top: 60px;
            }
            
            h4{
                @include rem(21);
                color: #fff;
                background-color: var(--main-color);
                padding: 10px 20px;
                text-align: center;
            }
            
            .hero_event_main_wrap{
                display: grid;
                // grid-template-columns: repeat(2, 1fr);
                grid-template-columns: 1fr;
                gap: 45px 40px;
                margin-top: 45px;
                
                @include media_query(){
                    grid-template-columns: 1fr;
                    gap: 30px;
                    margin-top: 25px;
                }
            }
            
            .hero_event_main_item{
                background-color: var(--sub-color);
                padding: 40px;
                
                @include media_query(){
                    padding: 20px;
                }
                
                h5{
                    color: var(--main-color);
                    text-align: center;
                    @include rem(24);
                    line-height: 1.7;
                    margin-bottom: 35px;
                    
                    @include media_query(){
                        @include rem(20);
                        margin-bottom: 20px;
                    }
                }
                
                p{
                    @include rem(15);
                    line-height: 1.7;
                    
                    @include media_query(){
                        @include rem(14);
                    }
                }
            }
        }
    }
    
    .hero_event_main_item_inenr{
        max-width: 500px;
        margin: 0 auto;
        
        dl{
            .event_dl_inner{
                
                & + .event_dl_inner{
                    margin-top: 30px;
                }
                
                dt{
                    font-weight: bold;
                    @include rem(18);
                }
                
                dd{
                    margin-top: 10px;
                    @include rem(15);
                    
                    ul{
                        padding-left: 2.5em;
                        
                        li{
                            line-height: 1.5;
                            
                            & + li{
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .btn_matching{
        margin-top: 30px;
    }
    
}







.wave_schedule{
    padding-top: 160px;
    background-image: url(../svg/icon_wave.svg);
    background-repeat: no-repeat;
    background-size: 55px 52px;
    background-position: top 85px center;
}

.hero .contact{
    
    padding: 120px 0;
    
    @include media_query(){
        padding: 0;
    }
}

.brain .contact{
    padding: 120px 0;
    
    @include media_query(){
        padding: 0;
    }
}




.extension_text{
    display: block;
    margin-top: 10px;
    color: #f00;
    @include rem(14);
}









.adopter_img{
    max-width: 1020px;
    margin: 0 auto;
    
    img{
        width: 100%;
        height: auto;
    }
}

.adopter_table_wrap{
    width: 100%;
    overflow: scroll;
    
    @include media_query(){
        padding: 0 20px;
    }
}

.adopter_table{
    width: 100%;
    max-width: 1020px;
    margin: 60px auto 0;
    
    @include media_query(){
        width: 1000px;
    }
    
    thead{
        tr{
            th,
            td{
                background-color: #787878;
                color: #fff;
                border: solid 1px #fff;
                padding: 12px;
                white-space: nowrap;
            }
        }
    }
    
    tbody{
        
        &:nth-of-type(1){
            tr{
                &:nth-of-type(even){
                    td{
                        background-color: #F4F4F4;
                    }
                }
            }
            
            th{
                background-color: #B4DDB2;
            }
        }
        &:nth-of-type(2){
            tr{
                &:nth-of-type(odd){
                    td{
                        background-color: #F4F4F4;
                    }
                }
            }
            
            th{
                background-color: #D2EBD1;
            }
        }
        &:nth-of-type(3){
            tr{
                &:nth-of-type(even){
                    td{
                        background-color: #F4F4F4;
                    }
                }
            }
            
            th{
                background-color: #E9F5E8;
            }
        }
        
        tr{
            // &:nth-of-type(odd){
            //     td{
            //         background-color: #F4F4F4;
            //     }
            // }
            
            th,
            td{
                border: solid 1px #BBBBBB;
                padding: 16px;
                line-height: 1.8;
            }
            
            th{
                white-space: nowrap;
                vertical-align: middle;
            }
            
            td{
                vertical-align: middle;
                
                &:first-of-type{
                    width: 15em;
                    text-align: center;
                }
                
                .adopter_table_td{
                    display: grid;
                    // grid-template-columns: 1fr 144px;
                    grid-template-columns: 1fr;
                    gap: 10px;
                    
                    img{
                        display: none;
                    }
                }
            }
        }
    }
}

.decline{
    max-width: 1020px;
    margin: 10px auto 0;
    text-align: right;
    @include rem(14);
}


.video{
    
    position: relative;
    max-width: 917px;
    margin: 60px auto 0;
    padding: 0 20px;
    
    @include media_query(){
        padding: 0;
    }
    
    h5{
        color: var(--main-color);
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.7;
        margin-bottom: 35px;
    }
    
    .youtube{
        position: relative;
        z-index: 2;
        width: 100%;
        padding-top: 56.25%;
        margin: 0 auto 30px;
        text-align: center;
        
        iframe{
            position: absolute;
            margin: auto;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
}









.achievement_img{
    max-width: 1020px;
    margin: 0 auto;
    
    img{
        width: 100%;
        height: auto;
    }
}

.achievement_wrap{
    max-width: 1020px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px 80px;
    margin: 100px auto 0;
    
    @include media_query(){
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        margin: 30px auto 0;
        gap: 30px 0;
    }
    
    // --swiper-theme-color: #fff;
    // --swiper-pagination-bullet-inactive-color: #fff;
    --swiper-pagination-bottom: 10px;
    
    .swiper-button-next:after, .swiper-button-prev:after{
        @include rem(24);
        color: #3C865A;
    }
    
    .swiper-pagination{
        --swiper-pagination-color: #3C865A;
    }
}

.achievement_item{
    width: 100%;
    max-width: 470px;
    margin: 0 auto;
    // width: calc(50% - 40px);
    // margin-left: 40px;
    
    @include media_query(){
        width: 100%;
    }
    
    .achievement_item_img{
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-bottom: 25px;
        
        @include media_query(){
            margin-bottom: 15px;
        }
        
        img{
            width: 100%;
            height: auto;
            aspect-ratio: 370 / 340;
            object-fit: contain;
        }
    }
    
    .achievement_item_text{
        div + div{
            margin-top: 20px;
        }
        
        span{
            display: block;
            border-left: solid 4px #3C865A;
            padding-left: 5px;
            color: #8E8E8E;
            margin-bottom: 12px;
        }
        
        p{
            @include rem(14);
            color: #1E1E1E;
            line-height: 1.8;
        }
        
        .name{
            p{
                font-weight: bold;
                @include rem(24);
                
                @include media_query(){
                    @include rem(20);
                }
            }
        }
        
        .overview_contents{
            border-top: solid 1px #D2D2D2;
            border-bottom: solid 1px #D2D2D2;
            padding: 15px 0;
        }
        
        .sales{
            ul{
                list-style: none;
                
                li{
                    & + li{
                        margin-top: 15px;
                    }
                    
                    a{
                        @include rem(15);
                        color: #3C865A;
                        text-decoration: none;
                        
                        &[href]{
                            text-decoration: underline;
                        }
                        
                        &[href]:hover{
                            opacity: .7;
                        }
                    }
                }
            }
        }
    }
}
