@charset "UTF-8";

//*------------------------------------*//
//	Base
//*------------------------------------*//

@use 'base/reset';
@use 'base/default';






//*------------------------------------*//
//	page
//*------------------------------------*//
@use 'page/sidebar';


@use 'page/common';
@use 'page/index';
@use 'page/hero';
@use 'page/brain';



//AOSのスタイル
@import 'aos/dist/aos.css';






@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
